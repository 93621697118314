<template>
  <el-drawer
    title="客户组列表"
    :visible.sync="dialog"
    direction="rtl"
    size="35%"
    style="font-size: 20px; font-weight: 600"
  >
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      class="from"
      label-width="80px"
    >
      <el-row type="flex" class="row-bg">
        <el-col :span="12">
          <el-form-item label=" " prop="GroupName">
            <el-input
              v-model="ruleForm.GroupName"
              clearable
              :disabled="ruleForm.Id ? true : false"
            ></el-input> </el-form-item></el-col
      ></el-row>
    </el-form>
    <div class="grouplist">
      <h2
        style="
          display: flex;
          align-items: center;
          justify-content: flex-end;
          border-bottom: 1px solid #ccc;
          margin: 0;
          padding: 0 8px 8px 8px;
        "
      >
        <!-- <span>群组列表</span> -->

        <el-button icon="el-icon-plus" type="primary" @click="handleAdd()"
          >新增</el-button
        >
      </h2>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="cCusName" label="客户名称" width="450">
        </el-table-column>
        <el-table-column prop="operate" label="操作">
          <template slot-scope="scope">
            <el-popconfirm
              title="确定要删除本条信息吗?"
              @confirm="handleDelete(scope)"
            >
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div style="height: 60px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="Closetable">取消</el-button>
      <el-button :plain="true" type="primary" @click="handleSubmit"
        >保存</el-button
      >
    </div>
    <ConsigneeInquiry
      type="checkbox"
      ref="ConsigneeInquiry"
      @select="setgrouplist"
    />
  </el-drawer>
</template>
<script>
import ConsigneeInquiry from "@/components/ConsigneeInquiry";
export default {
  components: { ConsigneeInquiry },
  props: { parentObj: { require: false, default: function(){ return {}}, type: Object } },
  data() {
    return {
      dialog: false,
      loading: false,
      timer: null,
      tableData: [],
      Cid: "",
      ruleForm: {
        GroupName: "",
      },
      rules: {
        GroupName: [
          { required: true, message: "请输入群组名称", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.tableData.length < 1)
          return this.$message.error("内容至少添加一项");

        this.btnLoading = true;
        this.$http

          .post("/BO/CustomerinfoGroup/SaveMasterData", {
            Id: this.ruleForm.Id,
            GroupName: this.ruleForm.GroupName,
            CompanyId: this.Cid,
            type: "hzq",
            GroupDetails: this.tableData.map((item) => {
              return { CustomerId: item.CustomerId || item.Id };
            }),
          })
          .then((res) => {
            console.log(res);
            if (res.Success) {
              this.$message.success("保存成功！");
              this.dialog = false;
              this.tableData = undefined;
              this.ruleForm.GroupName = "";
              this.parentObj.getDataList();
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    handleDelete(scope) {
      this.tableData.splice(scope.$index, 1);
    },
    setgrouplist(arr) {
      console.log(arr);
      this.tableData = this.tableData.concat(arr);
      let obj = {};
      this.tableData = this.tableData.reduce((pre, cur) => {
        if (obj[cur.cCusName]) {
          return pre;
        } else {
          pre.push(cur);
          obj[cur.cCusName] = cur.cCusName;
        }
        return pre;
      }, []);
    },
    handleAdd() {
      this.$refs.ConsigneeInquiry.openForm(this.Cid);
    },
    Closetable() {
      this.dialog = false;
      this.tableData = [];
      this.ruleForm.GroupName = "";
    },
    handleClose(done) {
      if (this.loading) {
        return;
      }
      this.$confirm("确定要提交表单吗？")
        .then((_) => {
          this.loading = true;
          this.timer = setTimeout(() => {
            done();
            // 动画关闭需要一定的时间
            setTimeout(() => {
              this.loading = false;
            }, 400);
          }, 2000);
        })
        .catch((_) => {});
    },
    cancelForm() {
      this.loading = false;
      this.dialog = false;
      clearTimeout(this.timer);
    },
    openForm(Cid, row) {
      this.dialog = true;
      this.Cid = Cid;
      this.tableData = [];
      this.ruleForm.GroupName = "";
      this.ruleForm.Id = "";
      if (row) {
        this.ruleForm.Id = row.Id;
        this.ruleForm.GroupName = row.GroupName;
        this.$http
          .post("/BO/CustomerinfoGroup/GetCustmerinfoDetailDtoList", {
            Search: { Keyword: row.Id },
          })
          .then((res) => {
            if (res.Success) {
              this.tableData = res.Data;
            }
          });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.iconStyle {
  color: #409eff;
}

.form {
  position: relative;
  padding-right: 10px;
}

.el-form-item {
  margin-bottom: 22px;
  margin-left: 13px;
}
</style>
