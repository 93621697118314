var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-drawer',{staticStyle:{"font-size":"20px","font-weight":"600"},attrs:{"title":"客户组列表","visible":_vm.dialog,"direction":"rtl","size":"35%"},on:{"update:visible":function($event){_vm.dialog=$event}}},[_c('el-form',{ref:"ruleForm",staticClass:"from",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"80px"}},[_c('el-row',{staticClass:"row-bg",attrs:{"type":"flex"}},[_c('el-col',{attrs:{"span":12}},[_c('el-form-item',{attrs:{"label":" ","prop":"GroupName"}},[_c('el-input',{attrs:{"clearable":"","disabled":_vm.ruleForm.Id ? true : false},model:{value:(_vm.ruleForm.GroupName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "GroupName", $$v)},expression:"ruleForm.GroupName"}})],1)],1)],1)],1),_c('div',{staticClass:"grouplist"},[_c('h2',{staticStyle:{"display":"flex","align-items":"center","justify-content":"flex-end","border-bottom":"1px solid #ccc","margin":"0","padding":"0 8px 8px 8px"}},[_c('el-button',{attrs:{"icon":"el-icon-plus","type":"primary"},on:{"click":function($event){return _vm.handleAdd()}}},[_vm._v("新增")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData}},[_c('el-table-column',{attrs:{"prop":"cCusName","label":"客户名称","width":"450"}}),_c('el-table-column',{attrs:{"prop":"operate","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-popconfirm',{attrs:{"title":"确定要删除本条信息吗?"},on:{"confirm":function($event){return _vm.handleDelete(scope)}}},[_c('el-button',{attrs:{"slot":"reference","type":"text"},slot:"reference"},[_vm._v("删除")])],1)]}}])})],1)],1),_c('div',{staticStyle:{"height":"60px"}}),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '5px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 99999,
    })},[_c('el-button',{on:{"click":_vm.Closetable}},[_vm._v("取消")]),_c('el-button',{attrs:{"plain":true,"type":"primary"},on:{"click":_vm.handleSubmit}},[_vm._v("保存")])],1),_c('ConsigneeInquiry',{ref:"ConsigneeInquiry",attrs:{"type":"checkbox"},on:{"select":_vm.setgrouplist}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }